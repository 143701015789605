import Axios from '../index'
import { GetPassportTokenRequest } from './model'

/**
 * Passport 获取用户授权口令
 * @param {*} option
 */
export function getPassportTokenApi(req: GetPassportTokenRequest): Promise<any> {
    return Axios.post(`/api/v1/passport/token`, req)
}
