






import { defineComponent, onMounted } from '@vue/composition-api';
import { passportCheckLoginState } from '@/passport/api'
import { useUserStore } from '@/pinia/modules/user'
import { getPassportTokenApi } from '@/api/passport'

export default defineComponent({
    name: 'PassportLogin',

    setup(props, { root }) {
        onMounted(async () => {
            const code = root.$route.query.code as string | undefined;
            const state = root.$route.query.state as string | undefined;
            const userStore = useUserStore()

            try {
                if (!state) {
                    throw new Error('No state received');
                }

                if (!passportCheckLoginState(state)) {
                    throw new Error('Invalid state');
                }

                if (!code) {
                    throw new Error('No code received');
                }

                const token = await getPassportTokenApi({ code })
                userStore.setUserInfo({ token })
                await userStore.updateUserInfo()
                root.$router.push('/')
            } catch (error) {
                await userStore.logout()
                console.error(`Authentication error: ${error}`)
                let errMsg = root.$t('login.errorMessage').toString()
                if (typeof error === 'object' && error !== null && 'errMsg' in error) {
                    const errorObj = error as { errMsg: string };
                    if (errorObj.errMsg) {
                        errMsg = errorObj.errMsg;
                    }
                }
                root.$router.push(`/login?passport=1&error=${encodeURIComponent(errMsg)}`)
            } 
        })

        return {};
    },
});
